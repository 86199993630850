import { Button, Input, Popover, Radio, Select } from "antd";
import React, { useState } from "react";
import { DocService } from "../../../../services/dataService";
import { useQuery } from "react-query";
import { twMerge } from "tailwind-merge";
import { useFilters } from "../../../../hooks/filters";

const Badge = ({ count, className }) => {
  return (
    <span
      className={twMerge(
        "text-xs text-neutral-500 ml-2 bg-neutral-100 px-1 rounded-md",
        className
      )}
    >
      {count}
    </span>
  );
};

const Label = ({ children }) => {
  return <div className="text-sm text-neutral-500 font-medium">{children}</div>;
};

const Filter = ({ onClear, filters, onFilterChange }) => {
  const filterSuggestions = useQuery(["filter-suggestions"], () =>
    DocService.getFilters()
  );

  const buckets = filterSuggestions.data?.buckets;
  if (!buckets) return null;
  return (
    <div className="relative">
      <div className="flex flex-col gap-4 max-w-[500px] max-h-[400px] overflow-y-auto p-4 pb-8">
        <div className="absolute -top-8 right-0 z-[100]">
          <Button type="link" onClick={onClear}>
            Clear All
          </Button>
        </div>
        <div className="flex flex-wrap gap-3">
          <div className="w-full basis-full">
            <Label>Authors</Label>
            <Select
              mode="tags"
              placeholder="Enter Authors"
              className="w-full"
              value={filters.authors}
              onChange={(value) => {
                onFilterChange({ ...filters, authors: value });
              }}
            >
              {buckets.authors?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                  <Badge count={item.doc_count} />
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full basis-full">
            <Label>Drug</Label>
            <Select
              mode="tags"
              placeholder="Enter Drug"
              className="w-full"
              value={filters.drug}
              onChange={(value) => {
                onFilterChange({ ...filters, drug: value });
              }}
            >
              {buckets.drug?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                  <Badge count={item.doc_count} />
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full basis-full">
            <Label>NCCN Cancer Type</Label>
            <Select
              mode="tags"
              placeholder="Enter Tissue Type"
              className="w-full"
              value={filters["nccn_tissue_type"]}
              onChange={(value) => {
                onFilterChange({
                  ...filters,
                  "nccn_tissue_type": value,
                });
              }}
            >
              {buckets["nccn_tissue_type"]?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                  <Badge count={item.doc_count} />
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="w-full basis-full">
            <Label>Old Tissue Type</Label>
            <Select
              mode="tags"
              placeholder="Enter Tissue Type"
              className="w-full"
              value={filters["old_primary_tissue_type"]}
              onChange={(value) => {
                onFilterChange({
                  ...filters,
                  "old_primary_tissue_type": value,
                });
              }}
            >
              {buckets["old_primary_tissue_type"]?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                  <Badge count={item.doc_count} />
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="basis-6/12 w-full">
            <Label>Relevance</Label>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              onChange={(e) => {
                onFilterChange({
                  ...filters,
                  "extraction.relevance": e.target.value,
                });
              }}
              value={filters["extraction.relevance"]}
            >
              <Radio.Button value={true}>Include</Radio.Button>
              <Radio.Button value={false}>Exclude</Radio.Button>
              <Radio.Button value={null}>All</Radio.Button>
            </Radio.Group>
          </div>
          <div className="basis-5/12">
            <Label>Validated</Label>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              onChange={(e) => {
                onFilterChange({
                  ...filters,
                  "extraction.is_validated": e.target.value,
                });
              }}
              value={filters["extraction.is_validated"]}
            >
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
              <Radio.Button value={null}>All</Radio.Button>
            </Radio.Group>
          </div>

          <div className="basis-6/12">
            <Label>Included in Review</Label>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              onChange={(e) => {
                onFilterChange({
                  ...filters,
                  included_in_review: e.target.value,
                });
              }}
              value={filters["included_in_review"]}
            >
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
              <Radio.Button value={null}>All</Radio.Button>
            </Radio.Group>
          </div>

          <div className="basis-5/12">
            <Label>Study Type</Label>
            <Select
              mode="tags"
              placeholder="Enter Study Type"
              className="w-full"
              value={filters["extraction.study_type"]}
              onChange={(value) => {
                onFilterChange({ ...filters, "extraction.study_type": value });
              }}
            >
              {buckets["extraction.study_type"]?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="basis-full">
            <Label>Therapeutic Association</Label>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              onChange={(e) => {
                onFilterChange({
                  ...filters,
                  "extraction.ta": e.target.value,
                });
              }}
              value={filters["extraction.ta"]}
            >
              <Radio.Button value="Positive">Positive</Radio.Button>
              <Radio.Button value="No Benefit">No Benefit</Radio.Button>
              <Radio.Button value="Negative">Negative</Radio.Button>
              <Radio.Button value="Inconclusive">Inconclusive</Radio.Button>
              <Radio.Button value="Not mentioned">Not Mentioned</Radio.Button>
              <Radio.Button value={null}>All</Radio.Button>
            </Radio.Group>
          </div>
          <div className="basis-full">
            <Label>Publication Year</Label>
            <Select
              mode="multiple"
              placeholder="Select Publication Year"
              className="w-full"
              value={filters["published_year"]}
              onChange={(value) => {
                onFilterChange({ ...filters, published_year: value });
              }}
            >
              {buckets["published_year"]?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="basis-full">
            <Label>Combination</Label>
            <Radio.Group
              defaultValue=""
              buttonStyle="solid"
              onChange={(e) => {
                onFilterChange({
                  ...filters,
                  "extraction.combination": e.target.value,
                });
              }}
              value={filters["extraction.combination"]}
            >
              <Radio.Button value="Combination">Combination</Radio.Button>
              <Radio.Button value="Original">Original</Radio.Button>
              <Radio.Button value={null}>All</Radio.Button>
            </Radio.Group>
          </div>
          <div className="basis-full">
            <Label>Import Date</Label>
            <Select
              mode="multiple"
              placeholder="Select Import Date"
              className="w-full"
              value={filters["import_date"]}
              onChange={(value) => {
                onFilterChange({ ...filters, import_date: value });
              }}
            >
              {buckets["import_date"]?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function SearchFilter() {
  const [currentSearch, setCurrentSearch] = useState("");

  const {
    search,
    setSearch,
    filters,
    setFilters,
    onClear: onClearFilters,
  } = useFilters();

  const onClear = () => {
    setCurrentSearch("");
    onClearFilters();
  };

  const activeFilters = Object.keys(filters).filter(
    (key) =>
      filters[key] !== null &&
      filters[key] !== "" &&
      !(Array.isArray(filters[key]) && filters[key].length === 0)
  ).length;

  return (
    <div>
      <div className="flex gap-2">
        <Input
          placeholder="Search"
          value={currentSearch ? currentSearch : search}
          onPressEnter={(e) => {
            setSearch(e.target.value);
          }}
          onChange={(e) => {
            if (e.target.value == "") {
              // clear the search
              onClear();
            } else {
              setCurrentSearch(e.target.value);
            }
          }}
        />

        <Popover
          title="Filters"
          content={
            <Filter
              filters={filters}
              onFilterChange={(filters) => {
                setFilters((prev) => ({ ...prev, ...filters }));
              }}
              onClear={onClear}
            />
          }
          trigger="click"
        >
          <Button>
            Filters
            {activeFilters > 0 && (
              <Badge
                count={activeFilters}
                className="text-blue-500 bg-blue-100"
              />
            )}
          </Button>
        </Popover>
      </div>
    </div>
  );
}
