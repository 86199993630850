import { BaseCRUDService } from "./baseService";
import { axios } from "./api";

const baseUrl = process.env.REACT_APP_API_URL;

class LabelCRUDService extends BaseCRUDService {
  get_lock(id) {
    return axios.get(`${this.base}/${id}/get_lock/`, {}).then((res) => res);
  }

  get_next(id, params) {
    return axios
      .get(`${this.base}/${id}/next/`, { params: params })
      .then((res) => res.data);
  }

  addLabel(id, data) {
    return axios
      .post(`${this.base}/${id}/add_label/`, data)
      .then((res) => res.data);
  }
}

class PubMedDataItemsCRUDService extends BaseCRUDService {
  search(params) {
    return axios
      .get(`${this.base}/search/`, { params: params })
      .then((res) => res.data);
  }

  stats(params) {
    return axios
      .get(`${this.base}/agg_info/`, { params: params })
      .then((res) => res.data);
  }

  scoringData(params) {
    return axios
      .get(`${this.base}/scoring/`, { params: params })
      .then((res) => res.data);
  }

  vectorSearch(params) {
    return axios
      .get(`${this.base}/vector_search/`, { params: params })
      .then((res) => res.data);
  }

  pubmedSearch(params) {
    return axios
      .get(`${this.base}/pubmed_search/`, { params: params })
      .then((res) => res.data);
  }

  drugScoring(params) {
    return axios
      .get(`${this.base}/scoring/`, { params: params })
      .then((res) => res.data);
  }
  importArticles(data) {
    return axios
      .post(`${this.base}/import_articles/`, data)
      .then((res) => res.data);
  }

  importOtherArticles(data) {
    return axios
      .post(`${this.base}/import_other_articles/`, data)
      .then((res) => res.data);
  }

  summarize(data) {
    return axios.post(`${this.base}/summarize/`, data).then((res) => res.data);
  }

  filter_suggest(params) {
    return axios
      .get(`${this.base}/filter_suggest/`, { params: params })
      .then((res) => res.data);
  }

  filter_suggest_all(params) {
    return axios
      .get(`${this.base}/filter_suggest_all/`, { params: params })
      .then((res) => res.data);
  }

  addFeedback(id, data) {
    return axios
      .post(`${this.base}/${id}/add_feedback/`, data)
      .then((res) => res.data);
  }

  uploadPdf(id, data) {
    return axios
      .post(`${this.base}/${id}/upload_pdf/`, data)
      .then((res) => res.data);
  }

  downloadPdf(id) {
    return axios
      .get(`${this.base}/${id}/download_pdf/`, { responseType: "blob" })
      .then((res) => res.data);
  }

  getAnnotations(id) {
    return axios
      .get(`${this.base}/${id}/get_annotations/`)
      .then((res) => res.data);
  }
  saveComment(id, data) {
    return axios
      .post(`${this.base}/${id}/comments/`, data)
      .then((res) => res.data);
  }

  getComments(id, params) {
    return axios
      .get(`${this.base}/${id}/comments/`, { params: params })
      .then((res) => res.data);
  }

  deleteComment(id) {
    return axios.delete(`${this.base}/comments/${id}/`).then((res) => res.data);
  }

  getValidations(id) {
    return axios
      .get(`${this.base}/${id}/get_validated_data/`)
      .then((res) => res.data);
  }

  otherDetails(id) {
    return axios
      .get(`${this.base}/${id}/other_details/`)
      .then((res) => res.data);
  }

  exportToExcel(params) {
    // Download the file response from the server
    return axios
      .get(`${this.base}/search_export/`, {
        params: params,
        responseType: "blob",
      })
      .then((res) => {
        // Create a blob object from the file response
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a link element to download the file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "pubmed_search.xlsx";
        // Append the link element to the DOM
        document.body.appendChild(link);
        // Dispatch a click event on the link element
        link.click();
        // Remove the link element from the DOM
        document.body.removeChild(link);
      });
  }
}

class PubMedConfigCRUDService extends BaseCRUDService {
  getConfig(params) {
    return axios
      .get(`${this.base}/data-config/`, { params: params })
      .then((res) => res.data);
  }

  updateConfig(data) {
    return axios
      .post(`${this.base}/data-config/`, data)
      .then((res) => res.data);
  }
}

class ValidationCRUDService extends BaseCRUDService {
  saveAnnotation(id, data) {
    return axios.post(`${this.base}/${id}/save/`, data).then((res) => res.data);
  }
  deleteAnnotation(id, data) {
    return axios
      .delete(`${this.base}/${id}/delete/`, { data: data })
      .then((res) => res.data);
  }

  getNext(id, params) {
    return axios
      .get(`${this.base}/${id}/next/`, {
        params: params,
      })
      .then((res) => res.data);
  }

  getSets(params) {
    return axios
      .get(`${this.base}/sets/`, { params: params })
      .then((res) => res.data);
  }

  getSetsV2(params) {
    return axios
      .get(`${this.base}/sets/v2/`, { params: params })
      .then((res) => res.data);
  }

  getTasksV2(params) {
    return axios
      .get(`${this.base}/v2/`, { params: params })
      .then((res) => res.data);
  }

  exportData() {
    return axios
      .post(
        `${this.base}/export/`,
        {},
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        // Download the file response from the server excel file xlsx
        // Create a blob object from the file response
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a link element to download the file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "pubmed_data.xlsx";
        // Append the link element to the DOM
        document.body.appendChild(link);
        // Dispatch a click event on the link element
        link.click();
        // Remove the link element from the DOM

        document.body.removeChild(link);
      });
  }
}

class ValidationV2CRUDService extends BaseCRUDService {
  saveAnnotation(id, data) {
    return axios.post(`${this.base}/${id}/save/`, data).then((res) => res.data);
  }
  deleteAnnotation(id, data) {
    return axios
      .delete(`${this.base}/${id}/delete/`, { data: data })
      .then((res) => res.data);
  }

  getNext(id, params) {
    return axios
      .get(`${this.base}/${id}/next/`, {
        params: params,
      })
      .then((res) => res.data);
  }

  getValidators(params) {
    return axios
      .get(`${this.base}/validators/`, { params: params })
      .then((res) => res.data);
  }

  getSets(params) {
    return axios
      .get(`${this.base}/sets/`, { params: params })
      .then((res) => res.data);
  }

  getTasks(params) {
    return axios
      .get(`${this.base}/`, { params: params })
      .then((res) => res.data);
  }

  exportData() {
    return axios
      .post(
        `${this.base}/export/`,
        {},
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        // Download the file response from the server excel file xlsx
        // Create a blob object from the file response
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a link element to download the file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "pubmed_data.xlsx";
        // Append the link element to the DOM
        document.body.appendChild(link);
        // Dispatch a click event on the link element
        link.click();
        // Remove the link element from the DOM

        document.body.removeChild(link);
      });
  }
}

class DocsCRUDService extends BaseCRUDService {
  search(params) {
    return axios
      .get(`${this.base}/search/`, { params: params })
      .then((res) => res.data);
  }

  getComments(id) {
    return axios.get(`${this.base}/${id}/comments/`).then((res) => res.data);
  }

  addComment(id, data) {
    return axios
      .post(`${this.base}/${id}/comments/`, data)
      .then((res) => res.data);
  }

  getFilters(params) {
    return axios
      .get(`${this.base}/filter_suggest_all/`, { params: params })
      .then((res) => res.data);
  }

  exportToExcel(params) {
    return axios
      .get(`${this.base}/search_export/`, {
        params: params,
        responseType: "blob",
      })
      .then((res) => res.data);
  }

  importArticles(data) {
    return axios
      .post(`${this.base}/import_articles/`, data)
      .then((res) => res.data);
  }

  importOtherArticles(data) {
    return axios
      .post(`${this.base}/import_other_articles/`, data)
      .then((res) => res.data);
  }

  getAggregatedData(params) {
    return axios
      .get(`${this.base}/agg_info/`, { params: params })
      .then((res) => res.data);
  }
}
export const LabelTask = new BaseCRUDService("label/tasks");

export const PubmedData = new BaseCRUDService("pubmed/data");

export const PubmedConfig = new PubMedConfigCRUDService("pubmed");

export const ValidationTask = new ValidationCRUDService("validation");

export const ValidationTaskV2 = new ValidationV2CRUDService("validation_v2");


export const PubmedDataItems = new PubMedDataItemsCRUDService(
  "pubmed/data/items"
);

export const LabelItem = new LabelCRUDService("label/tasks/items");

export const SupplierService = new BaseCRUDService("supplier");
export const DocService = new DocsCRUDService("docs");
class HelperServices_ {
  getAllUsers() {
    return axios.get(`${baseUrl}/auth/users/all/`).then((res) => res.data);
  }

  drive_files(params) {
    return axios
      .get(`${baseUrl}/drive_files`, { params: params })
      .then((res) => res.data);
  }
}

class LLMService_ {
  getGPTPredictions(data) {
    return axios.post(`${baseUrl}/llm/gpt/`, data).then((res) => res.data);
  }

  getClaudePredictions(data) {
    return axios.post(`${baseUrl}/llm/claude/`, data).then((res) => res.data);
  }

  createGPTSummary(data) {
    return axios
      .post(`${baseUrl}/llm/research_topic/`, data)
      .then((res) => res.data);
  }

  getAllSummary(params) {
    return axios
      .get(`${baseUrl}/llm/research_topic/`, { params: params })
      .then((res) => res.data);
  }
}

class BookmarkPage extends BaseCRUDService {
  addItem(data) {
    return axios.post(`${this.base}/add_item/`, data).then((res) => res.data);
  }

  addItems(data) {
    return axios
      .post(`${this.base}/add_multiple_items/`, data)
      .then((res) => res.data);
  }

  checkItem(item_id) {
    return axios
      .get(`${this.base}/check_item/`, {
        params: { item_id: item_id },
      })
      .then((res) => res.data);
  }

  deleteItem(id) {
    return axios.delete(`${this.base}/item/${id}/`).then((res) => res.data);
  }

  getItems(id, params) {
    return axios
      .get(`${this.base}/${id}/items/search/`, { params: params })
      .then((res) => res.data);
  }

  exportItems(id) {
    return axios
      .get(`${this.base}/${id}/export/`, {
        responseType: "blob",
      })
      .then((res) => {
        // Download the file response from the server excel file xlsx
        // Create a blob object from the file response
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a link element to download the file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "bookmarks.xlsx";
        // Append the link element to the DOM
        document.body.appendChild(link);
        // Dispatch a click event on the link element
        link.click();
        // Remove the link element from the DOM

        document.body.removeChild(link);
      });
  }
}

export const LLMService = new LLMService_();
export const HelperServices = new HelperServices_();
export const BookmarkService = new BookmarkPage("bookmark");

export const PipelineRunService = new BaseCRUDService("pipeline_run");
